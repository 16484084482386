.side-menu {


}
.box {
    transition: transform 0.5s ease;
    position: absolute;
    background-color: black;
    color: white;
    height: 100%;
    z-index: 1;
}

.box.active {
    transform: translateX(0px);
    z-index: 1;
}